import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CURRENT_USER,
  GUEST_USER,
  MAX_NAME_LENGTH,
  PHONE_NUMBER_MASK,
} from '../../constants';

import MaskedInput from 'react-text-mask';
import { getUser, getVisitorId } from 'src/helper/helperMethods';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';
import { contactInfoFormSchema } from 'src/validationSchemas/contactInfoFormSchema';
import { ContactInfoFormFields } from '../../models/forms.model';
import useProfile from '../../react-query-hooks/useProfile';
import useUpdateProfile from '../../react-query-hooks/useUpdateProfile';
import FormField from '../FormFields/FormField';
import { updateUserData } from '../../Features/AccountSetup/redux/slices';

interface IContactInfoForm {
  closeModal?: (boolean) => void;
  userId?: number;
  currentGuestUserInfo?: any;
  isGuest?: boolean;
  handleSubmitAsGuest?: () => void;
}

const ContactInfoForm: React.FC<IContactInfoForm> = ({
  closeModal,
  userId,
  currentGuestUserInfo,
  isGuest,
  handleSubmitAsGuest,
}) => {
  const { user: authInfo } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const user = isGuest ? null : authInfo.id;
  const { data } = useProfile(user, authInfo.type);
  const { mutateAsync, isLoading } = useUpdateProfile();
  const queryClient = useQueryClient();
  const [readonly, setReadonly] = useState(false);

  const initialFormState: ContactInfoFormFields = {
    first_name:
      (currentGuestUserInfo?.isInfo && currentGuestUserInfo?.first_name) ||
      data?.first_name ||
      '',
    last_name:
      (currentGuestUserInfo?.isInfo && currentGuestUserInfo?.last_name) ||
      data?.last_name ||
      '',
    email: currentGuestUserInfo?.email || data?.email || '',
    phone: currentGuestUserInfo?.phone || data?.phone || '',
  };

  useEffect(() => {
    if (initialFormState.email && authInfo.id && !isGuest) {
      setReadonly(true);
    }
    if (!currentGuestUserInfo) {
      currentGuestUserInfo = getUser();
    }
  }, [initialFormState.email]);

  const prepareContactInfo = (values, valuesObj) => {
    if (
      values?.first_name !=
      (initialFormState?.first_name || currentGuestUserInfo.first_name)
    ) {
      valuesObj.first_name = values?.first_name
        ? values.first_name
        : currentGuestUserInfo.first_name;
    }
    if (
      values?.last_name !=
      (initialFormState.last_name || currentGuestUserInfo.last_name)
    ) {
      valuesObj.last_name = values?.last_name
        ? values.last_name
        : currentGuestUserInfo.last_name;
    }
    if (
      values?.email != (initialFormState.email || currentGuestUserInfo.email)
    ) {
      valuesObj.email = values?.email
        ? values.email
        : currentGuestUserInfo.email;
    }
    if (
      values?.phone != (initialFormState.phone || currentGuestUserInfo.phone)
    ) {
      valuesObj.phone = values?.phone
        ? values.phone
        : currentGuestUserInfo.phone;
    }

    let finalValues = {
      id: authInfo?.userId || authInfo?.visitor_id || '',
      first_name: valuesObj?.first_name || values?.first_name || '',
      last_name: valuesObj?.last_name || values?.last_name || '',
      email: valuesObj?.email || values?.email || '',
      phone: valuesObj?.phone || values?.phone || '',
    };
    return finalValues;
  };

  const isGuestUser = () => {
    return (
      authInfo.type === GUEST_USER ||
      !authInfo.id ||
      (!currentGuestUserInfo.first_name &&
        !currentGuestUserInfo.last_name &&
        !currentGuestUserInfo.phone)
    );
  };
  const handleFormSubmission = async (
    values: ContactInfoFormFields,
    { setSubmitting }: any,
  ) => {
    let user = getUser();
    user.full_name = values.first_name.concat(' ', values.last_name);
    let updatedUser = {
      ...user,
      visitor_id: getVisitorId(),
      isInfo: true,
      ...values,
    };
    localStorage.setItem(CURRENT_USER, JSON.stringify(updatedUser));
    dispatch(updateUserData(updatedUser));
    closeModal(true);
    isGuest && handleSubmitAsGuest();
  };

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={contactInfoFormSchema}
      onSubmit={handleFormSubmission}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        submitForm,
      }) => (
        <form
          className="new_form_design my-0 mx-auto delivery_location_select full_width_mob"
          onSubmit={handleSubmit}
        >
          <FormField
            labelText={'First Name'}
            type={'text'}
            name={'first_name'}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.first_name}
            maxLength={MAX_NAME_LENGTH}
          />
          <FormField
            labelText={'Last Name'}
            type={'text'}
            name={'last_name'}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.last_name}
            maxLength={MAX_NAME_LENGTH}
          />
          <FormField
            // readonly = {readonly}
            labelText={'Email Address'}
            type={'email'}
            name={'email'}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.email}
          />
          <div>
            <label htmlFor="phone">Phone Number</label>
            <Field
              name="phone"
              render={({ field }) => (
                <MaskedInput
                  {...field}
                  mask={PHONE_NUMBER_MASK}
                  id="phone"
                  placeholder="(223) 123-1234"
                  type="text"
                  value={values.phone}
                  onChange={(e) =>
                    setFieldValue(
                      'phone',
                      e.target.value.replace(/[() -]/g, ''),
                    )
                  }
                  guide={true}
                  className={`
                          ${
                            errors.phone && touched.phone
                              ? 'input_error'
                              : 'text-input'
                          }
                            form-control`}
                />
              )}
            />
            {errors.phone && touched.phone && (
              <div className="input-feedback clr-dark-red f-s14 font-rale mt-1 d-inline-block">
                {errors.phone}
              </div>
            )}
          </div>
          {/* <FormField
            labelText={"Phone Number"}
            type={"text"}
            name={"phone"}
            errors={errors}
            touched={touched}
            onChange={handleChange}
            value={values.phone}
            placeholder={"e.g. (223) 123-1234"}
          /> */}
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className="btn-large py-2 f-s16 mb-4 mt-4 text-uppercase"
              disabled={isSubmitting || isLoading}
              onClick={submitForm}
            >
              {isGuest
                ? 'PROCEED AS GUEST'
                : userId
                ? 'CONFIRM CHANGES'
                : 'CONFIRM CONTACT INFO'}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ContactInfoForm;
