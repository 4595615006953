import FutureOrderListing from 'src/Features/profileDetail/FutureOrders/FutureOrderListing/FutureOrderListing';
import { useAppSelector } from 'src/redux/store/store';
import styles from './header.module.scss';
interface Item {
  name: string;
  order_id: number;
}

interface Location {
  name: string;
}

interface Payload {
  id: number;
  place_time: string;
  qsr_status: any; // You may want to specify a more specific type here
  business_date: string;
  customer_id: number;
  is_closed: number;
  location_id: number;
  closed_time: any; // You may want to specify a more specific type here
  is_failed: number;
  is_cancelled: number;
  is_future_order: number;
  fire_time: string;
  promise_time: string;
  source: string;
  points: any; // You may want to specify a more specific type here
  platform: string;
  order_status: string;
  show_receipt: number;
  reorder: number;
  pluse_order: number;
  remaining_time: string;
  order_type: string;
  items: Item[];
  location: Location;
}
interface ModalProps {
  data: Payload[];
  setMultiOrderModal: any;
}

const MultiOrderModal: React.FunctionComponent<ModalProps> = ({
  data,
  setMultiOrderModal,
}) => {
  const { orderId, is_edit_order } = useAppSelector((state) => state.cart);
  return (
    <>
      <div className="py-4">
        <h2 className="f-s30 f-sm-s18 font-Cls">Catering Orders</h2>
        <hr className="mt-3 mb-4" />
        <div className={`p-md-3 ${styles.multi_order_modal}`}>
          {data.map((order) => {
            if (is_edit_order && orderId === order.id) return;
            const inputDateTime = new Date(order.promise_time);
            const hours = inputDateTime.getHours();
            const minutes = inputDateTime.getMinutes();
            const amPm = hours >= 12 ? 'PM' : 'AM';
            const formattedTime = `${(hours % 12)
              .toString()
              .padStart(2, '0')}:${minutes
              .toString()
              .padStart(2, '0')} ${amPm}`;
            return (
              <>
                <FutureOrderListing
                  orderType={order.order_type}
                  status={order.order_status}
                  date={order.promise_time}
                  time={formattedTime}
                  address={order.location_id}
                  person={order.location.name}
                  remainingTime={order.remaining_time}
                  fireTime={order.fire_time}
                  id={order.id}
                  setMultiOrderModal={setMultiOrderModal}
                />
                <hr />
              </>
            );
          })}
          {/* <FutureOrderListing
            orderType="Pick up"
            status="in progress"
            date="Wednesday, January 7, 2022"
            time="4:00pm"
            address="Valley Fair"
            person="leader"
          /> */}
        </div>
      </div>
    </>
  );
};

export default MultiOrderModal;
