import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { unauthorizeLogout } from 'src/Features/AccountSetup/redux/actions';
import { getUser } from 'src/helper/helperMethods';
import store from 'src/redux/store/store';


const {dispatch} = store;

export const requestHandler = (request: AxiosRequestConfig) => {
  const authInfo = getUser();
  if (authInfo.token) {
    request.headers['Authorization'] = 'Bearer ' + authInfo.token;
    request.headers['token'] = authInfo.authentication_token;
  }
  return request;
};

export const successResponseHandler = (response: AxiosResponse) => {
  return response;
};

export const errorResponseHandler = (error: AxiosError) => {
  const authInfo = getUser();
  if (error?.response?.status === 401 && authInfo.token) {
    unauthorizeLogout(dispatch)
  }
  return Promise.reject(error);
};
