import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { handleIsLiveLocationRedirection } from 'src/helper/checkout/LocationHelper';
import { isGuestUser } from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import { DeliveryAddressFromFields } from 'src/models/forms.model';
import useAddAddress from 'src/react-query-hooks/useAddAddress';
import useGetAllStates from 'src/react-query-hooks/useGetAllStates';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import FormField from '../FormFields/FormField';
import GooglePlacesSearchInput from '../GooglePlacesSearch';
import { MAX_STREET_2_LENGTH } from 'src/constants';

interface IVisitorDeliveryAddress {
  setAddress?: (isShown) => void;
  setNewAddedAddressForDelivery: (delivery_address) => void;
  checkNearestStoreLocation: (delivery_address) => any;
  handleShowDeliveryMethodModal?: (boolean) => void;
  showItemDeliveryDateTime?: boolean;
  saveSelectedOrderType?: string;
  handleOrderType?: (orderType: string) => void;
  handleFormSubmission?: any;
  handleLocation?: (values, address) => void;
  isItMobile?: boolean;
  isFindingNearest?: boolean;
  isNearestStoreAvailable?: boolean;
  closeNewAddressForm: () => void;
  selectedAddress: any;
  userAddresses: any;
}

const VisitorDeliveryAddress = (props: IVisitorDeliveryAddress) => {
  const {
    setAddress,
    checkNearestStoreLocation,
    handleShowDeliveryMethodModal,
    setNewAddedAddressForDelivery,
    showItemDeliveryDateTime,
    saveSelectedOrderType,
    handleOrderType,
    closeNewAddressForm,
    selectedAddress,
    userAddresses,
  } = props;
  const deliveryAddress = useAppSelector(
    (state) => state.cart?.orderDetails?.delivery_address || '',
  );
  const initialFormState: DeliveryAddressFromFields = {
    address_name: '',
    optional_address_details:
      deliveryAddress?.apartment_no && !deliveryAddress?.id
        ? deliveryAddress.apartment_no
        : '',
    // street_name: "",
    // city: "",
    // state: "",
    // zipcode: "",
  };
  const orderTypeDispatch = useAppDispatch();
  const { data: allStates, isFetching } = useGetAllStates();
  const [statesList, setStatesList] = useState([]);
  const [street_name, setStreet_name] = useState(
    deliveryAddress?.street_name || '',
  );
  const [isTypedAddress, setIsTypedAddress] = useState<boolean>(false);
  const [city, setCity] = useState(deliveryAddress?.city || '');
  const [state, setState] = useState(deliveryAddress?.state || '');
  const [zipcode, setZipcode] = useState(deliveryAddress?.zipcode || '');
  const [country, setCountry] = useState(deliveryAddress?.zipcode || '');
  const [fullAddress, setFullAddress] = useState(
    deliveryAddress?.fullAddress || '',
  );
  const [isAddressChanged, setIsAddressChanged] = useState(false);
  const [isSaveAddress, setIsSaveAddress] = useState(false);
  const { mutateAsync: addAddress } = useAddAddress();
  const { user } = useAppSelector((state) => state.user);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (!isFetching && allStates?.length > 0) {
      setStatesList(
        allStates.map((state) => {
          return { label: state.abbr, value: state.abbr };
        }),
      );
    }
  }, [allStates]);

  useEffect(() => {
    if (deliveryAddress) {
      initialFormState.address_name = !deliveryAddress?.id
        ? deliveryAddress?.address_name
        : '';
      initialFormState.street_name = !deliveryAddress?.id
        ? deliveryAddress?.street_name
        : '';
      initialFormState.city = !deliveryAddress?.id ? deliveryAddress?.city : '';
      initialFormState.state = !deliveryAddress?.id
        ? deliveryAddress?.state
        : '';
      initialFormState.zipcode = !deliveryAddress?.id
        ? deliveryAddress?.zipcode
        : '';
    }
  }, [deliveryAddress]);
  const saveAddressToUserProfile = (
    values: DeliveryAddressFromFields,
    delivery_address,
  ) => {
    const { street_name, city, zipcode, state } = delivery_address;
    let { address_name } = values;
    address_name = address_name?.trim();
    const modifiedValues = {
      ...delivery_address,
      customer_id: user.id,
      isDefault: false,
      ...(address_name ? { address_name } : { address_name: street_name }),
    };
    return addAddress(
      {
        deliveryAddress: modifiedValues,
        authentication_token: user.authentication_token,
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.refetchQueries(['profile', user.id]);
          delivery_address = {
            ...delivery_address,
            id: data.id,
          };
          setNewAddedAddressForDelivery({
            value: data.address_name,
            label: data.address_name,
            id: data.id,
            isDefault: data.is_default,
            street: data.street_name,
            ...data,
          });
          closeNewAddressForm();
          // orderTypeDispatch(updateOrderDetails({"delivery_address" : delivery_address}))
        },
        onError: (error, variables, context) => {
          Toast_Func({ status: false, message: error[0] });
        },
      },
    );
  };

  const handleAddressChange = (e) => {
    setStreet_name(e.streetName);
    setCity(e.cityName);
    setState(e.state);
    setZipcode(e.postalCode);
    setFullAddress(e.address);
    setCountry(e.country);
  };

  const handleFormSubmission = async (
    values: DeliveryAddressFromFields,
    { setSubmitting }: any,
  ) => {
    if (
      isTypedAddress ||
      street_name?.trim() === '' ||
      city?.trim() === '' ||
      state?.trim() === '' ||
      zipcode?.trim() === ''
    ) {
      Toast_Func({
        status: false,
        message: 'Address should contain valid / nearby street city and state.',
      });
      setSubmitting(false);
      return;
    }

    const delivery_address = {
      address_name: values?.address_name || street_name,
      city: city,
      state: state,
      street: street_name,
      zipcode: zipcode,
      street_name: street_name,
      country: country,
      apartment_no: values.optional_address_details,
      full_address: fullAddress,
    };
    if (!isGuestUser(user) && isSaveAddress) {
      await saveAddressToUserProfile(values, delivery_address);
    } else {
      const response = await checkNearestStoreLocation(delivery_address);
      if (!response) {
        setSubmitting(false);
      } else {
        if (response && !response.is_live) {
          handleIsLiveLocationRedirection(
            saveSelectedOrderType,
            response,
            props.isItMobile,
          );
        } else {
          props.handleLocation(response, {
            ...delivery_address,
            isGuestAddress: isGuestUser(user),
          });
          setSubmitting(false);
        }
      }
    }
    setSubmitting(false);
  };
  return (
    <div>
      {!isGuestUser(user) && userAddresses?.length ? (
        <button
          type="button"
          className="btn btn-custom back-arrow-btn f-s14 px-0 mb-0 "
          onClick={() => closeNewAddressForm()}
        >
          Back
        </button>
      ) : null}
      <p
        className={` clr-red-dark text-center f-s14 pb-4`}
        hidden={props.isNearestStoreAvailable}
      >
        Option unavailable. Unfortunately, you’re too far from the nearest Cafe
        Zupas location.
      </p>
      <div className="">
        <div>
          <Formik
            initialValues={initialFormState}
            onSubmit={handleFormSubmission}
            // validationSchema={deliveryAddressSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                className={`position-relative new_form_design`}
                onSubmit={handleSubmit}
              >
                {!isGuestUser(user) && (
                  <div className="mt-3">
                    <FormField
                      labelText={'Name Your Saved Address'}
                      name="address_name"
                      value={values?.address_name}
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                        if (
                          e?.target?.value?.trim() !==
                          deliveryAddress?.address_name?.trim()
                        ) {
                          setIsAddressChanged(true);
                        }
                      }}
                      errors={errors}
                      touched={touched}
                      placeholder="Name"
                    />
                  </div>
                )}
                {/* <FormField
                                    labelText={"Address"}
                                    name="street_name"
                                    type="text"
                                    value={values?.street_name}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    placeholder="Address"
                                />
                                <FormField
                                    labelText={"City"}
                                    name="city"
                                    value={values?.city}
                                    type="text"
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    placeholder="City"
                                /> */}
                <Row>
                  <GooglePlacesSearchInput
                    defaultAddress={fullAddress}
                    handleAddressChange={handleAddressChange}
                    setIsAddressChanged={setIsAddressChanged}
                    setIsTypedAddress={setIsTypedAddress}
                  />
                  {/* <Col sm={12} md={4}>
                                        <div className="form-group custom_select">
                                            <FormField
                                                options={statesList}
                                                //value={values?.state}
                                                labelText={"State"}
                                                name="state"
                                                type={"select"}
                                                placeholder={values?.state ? values?.state : "State"}
                                                errors={errors}
                                                touched={touched}
                                                isSearchable={true}
                                                onChange={(value) => setFieldValue('state', value.value)}
                                                inputFieldClass={"custom_select mb-0"}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <FormField
                                            labelText={"zip"}
                                            name="zipcode"
                                            type="text"
                                            value={values?.zipcode}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            placeholder="Zip Code"
                                        />
                                    </Col> */}
                </Row>
                <div className="my-3">
                  <FormField
                    labelText={
                      'Apt, Floor, Suite, Building, Company (Optional)'
                    }
                    name="optional_address_details"
                    value={values.optional_address_details}
                    type="text"
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    placeholder="Apt, Floor, Suite, Building, Company"
                    maxLength={MAX_STREET_2_LENGTH}
                  />
                </div>
                {!isGuestUser(user) ? (
                  <>
                    {/* <input
                      type="checkbox"
                      name="radio"
                      id="delivery"
                      onClick={(e) => {
                        setIsSaveAddress(!isSaveAddress);
                      }}
                      checked={isSaveAddress}
                    /> */}
                    <div className="form-group theme_check_boxes">
                      <input
                        name="saveAddress"
                        type="checkbox"
                        id="delivery"
                        onChange={(e) => {
                          setIsSaveAddress(!isSaveAddress);
                        }}
                        checked={isSaveAddress}
                      />
                      <label
                        htmlFor="delivery"
                        className="d-flex align-items-center f-s18 f-sm-s16 ms-2 font-rale"
                      >
                        <span className="box_wrap"></span> Save address
                        information to my account
                      </label>
                    </div>
                  </>
                ) : null}
                {selectedAddress && isGuestUser(user) && (
                  <div className="mt-1">
                    <span className="d-block f-s14 font-Visby-cf f-w5">
                      {selectedAddress.address_name}
                    </span>
                    <span className="d-block f-s14 font-Visby-cf f-w5">
                      {selectedAddress.street_name},{' '}
                      {selectedAddress?.apartment_no}
                    </span>
                    <span className="d-block f-s14 font-Visby-cf f-w5">
                      {selectedAddress.city}, {selectedAddress.state}{' '}
                      {selectedAddress.zipcode}
                    </span>
                  </div>
                )}

                <div className="d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn-large"
                    disabled={isSubmitting}
                  >
                    {props.isFindingNearest
                      ? 'Searching for nearest store...'
                      : 'Continue'}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default VisitorDeliveryAddress;
