import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useCookie from 'react-use-cookie';
import Loader from 'src/components/Loader/Loader';
import { Toast_Func } from 'src/helper/toast.helper';
import useKeypress from 'src/hooks/useKeypress';
import { SectionDataModel } from 'src/models/sections.model';
import useMarketLocations from 'src/react-query-hooks/useLocations';
import { customerBusinessService } from 'src/services';
import { emailSubsFormValidationSchema } from 'src/validationSchemas/contactUsSchema';
import * as CONSTANT from '../../../constants';
import Confirmation from './Confirmation';
import styleClasses from './Popup.module.scss';
import FormField from 'src/components/FormFields/FormField';
import { useAppSelector } from 'src/redux/store/store';

interface footerProps {
  showPopup: boolean;
  closePopup?: () => void;
}
interface UserFormData {
  email: string;
}

const EmailListModel: React.FunctionComponent<footerProps> = ({
  showPopup,
  closePopup,
}) => {
  useKeypress('Escape', () => {
    handleClose();
  });

  let history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [cookies, setCookie] = useCookie('EmailModal');
  const { data: locations, isFetching, isSuccess } = useMarketLocations();

  const defaultUserFormState: UserFormData = {
    email: '',
  };
  let { user } = useAppSelector((state) => state.user);
  const [Confirmation_data] = useState<SectionDataModel>({
    section_title: 'THANK YOU!',
    section_details: 'You are now on our mailing list.',
    sub_title: 'Work Up an Appetite?',
    button_class: '',
    Section_controller: 'pb-4 pb-sm-5',
    button_text: 'START AN ORDER',
    button_link: CONSTANT.ROUTE_ORDER_LANDING,
  });
  const [UserFormState, setUserFormState] =
    useState<UserFormData>(defaultUserFormState);
  const handleClose = () => {
    closePopup();
    setshowConfirmation(false);
  };

  const handCloseFooter = (e) => {
    e.preventDefault();
    handleClose();
    if (e.target.pathname == '/privacyPolicy') {
      history.push(CONSTANT.PRIVACY_POLICY_ROUTE);
    }
    if (e.target.pathname == '/terms') {
      history.push(CONSTANT.TERMS_ROUTE);
    }
    window.location.reload();
  };

  /**
   * update the tabindex of the buttons and anchor tags.
   */
  const updateTabIndex = (val: string) => {
    setTimeout(() => {
      document
        .querySelectorAll(
          `#root[aria-hidden=true] a[href],
        #root[aria-hidden=true] area[href],
        #root[aria-hidden=true] input:not([disabled]),
        #root[aria-hidden=true] select:not([disabled]),
        #root[aria-hidden=true] textarea:not([disabled]),
        #root[aria-hidden=true] button:not([disabled]),
        #root[aria-hidden=true] [tabindex]:not([disabled]),
        #root[aria-hidden=true] [contenteditable=true]:not([disabled])`,
        )
        .forEach(function (e) {
          // @ts-ignore: Unreachable code error
          e.tabIndex = val;
        });
    }, 600);
  };

  useEffect(() => {
    if (showPopup) {
      updateTabIndex('-1');
      if (cookies !== 'true') {
        setCookie('true');
      }
    }
  }, [showPopup, cookies]);

  useEffect(() => {
    if (isSuccess) {
      updateTabIndex('-1');
    }
  }, [isSuccess]);

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setLoading(true);
    try {
      const payload = {
        customer_id: user.id,
        visitor_id: user.visitor_id,
        ...values,
      };

      await customerBusinessService.subscribeEmail(payload);
      resetForm();
      setshowConfirmation(true);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } catch (error) {
      Toast_Func({
        status: false,
        message: error.response.data.message,
      });
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Row>
          <Col xs="12" sm="12" md="6">
            <Modal
              className={`${styleClasses.modal_box} ${styleClasses.newsletter_modal} footer-modal-wrap new_form_design theme-modal-style   border-radius-15`}
              show={showPopup}
              onClose={handleClose}
              backdrop="static"
              tabIndex="-1"
              keyboard={false}
              aria-labelledby="Newsletter2"
              aria-describedby="Description2"
              aria-label="close_button2"
              id="notes2"
              centered
            >
              <Modal.Header
                className={styleClasses.unset_style}
                id="close_button2"
              >
                <button
                  className={`${styleClasses.modal_close_button} border-0 close e-club-close-button p-0`}
                  onClick={handleClose}
                  aria-controls="notes2"
                >
                  Close
                </button>
              </Modal.Header>
              <Modal.Body
                className="app_container_wrap px-1 py-2"
                id="Description2"
              >
                <Container>
                  {showConfirmation ? (
                    ' '
                  ) : (
                    <Row>
                      <Col xs="12" sm="12" className="pt-4">
                        <h5
                          id="Newsletter2"
                          className={`f-s22 font-Cls text-center fw-normal  mb-4 text-capitalize`}
                        >
                          ALWAYS KNOW WHAT’S NEW!
                        </h5>
                        <p
                          className={`${styleClasses.modal_description} f-s14  text-center`}
                        >
                          Nourish the Good Life with exclusive Café Zupas
                          offers, updates and seasonal menus sent straight to
                          your inbox.
                        </p>
                      </Col>
                    </Row>
                  )}
                  {showConfirmation ? (
                    <Confirmation Confirmation_data={Confirmation_data} />
                  ) : isLoading || isFetching ? (
                    <Loader className={styleClasses.recentOrderSpinner} />
                  ) : (
                    <Row>
                      <Col xs="12" sm="12" className="text-center mt-3">
                        <Formik
                          onSubmit={handleFormSubmit}
                          initialValues={UserFormState}
                          validationSchema={emailSubsFormValidationSchema}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            submitForm,
                          }) => (
                            <React.Fragment>
                              <Form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md={12} xs={12} className="text-start">
                                    <FormField
                                      name="email"
                                      type={'text'}
                                      value={values.email}
                                      onChange={handleChange}
                                      labelText="Email"
                                      placeholder="Enter Your Email"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </Col>
                                </Row>

                                <Col className="pb-3">
                                  <button
                                    className={`${styleClasses.signup_button} btn-large dark-grey mt-3`}
                                    disabled={isSubmitting}
                                    type="submit"
                                    value="SIGN UP"
                                    onClick={submitForm}
                                  >
                                    SIGN UP
                                  </button>
                                  <p className={styleClasses.bottom_text}>
                                    By clicking ‘SIGN UP’, you accept our{' '}
                                    <Link
                                      to={'/privacyPolicy'}
                                      onClick={handCloseFooter}
                                    >
                                      Privacy Policy
                                    </Link>
                                    .
                                  </p>
                                </Col>
                              </Form>
                            </React.Fragment>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EmailListModel;
